body, input {
    font-family: shamel;
}


.card-title-text{
    font-family:shamel;
}

.MainFont {
    font-family: shamel;
}
.text-center {
    font-family: shamel;
}

.mat-form-field-label {
    font-family: shamel;
}
    .mat-form-field-label span {
        font-family: shamel;
        letter-spacing: normal;
    }

    .mat-form-field-label mat-label {
        font-family: shamel;
        letter-spacing: normal;
    }

.mat-sort-header {
    font-family: shamel;
}

.mat-button-wrapper {
    font-family: shamel;
}
.mat-dialog-title {
    font-family: shamel;
}

.shop-top-toolbar h1 {
    font-family: shamel;
}
.mat-tab-label-content {
    font-family: shamel;
}

.mat-select-value-text {
    font-family: shamel;
    letter-spacing: normal;
}





.mat-ripple-element {
    font-family: shamel;
}
.mat-slide-toggle-content {
    font-family: shamel;
}
    .mat-slide-toggle-content span {
        font-family: shamel;
    }

.mat-ripple {
    font-family: shamel;
}
    .mat-ripple span {
        font-family: shamel;
    }

.mat-slide-toggle-label {
    font-family: shamel;
}
    .mat-slide-toggle-label span {
        font-family: shamel;
    }

.mat-chip {
    font-family: shamel;
    letter-spacing: normal;
}
    .mat-chip span {
        font-family: shamel;
        letter-spacing: normal;
    }


