@mixin ngx-datatable-theme($theme) {
  $color: map-get($theme, color);
  $background: map-get($color, background);
  $text-color: map-get(map-get($color, foreground), text);
  $disabled-text-color: map-get(map-get($color, foreground), disabled-text);
  $background-card: map-get($background, card);

  .ngx-datatable.material {
    background-color: $background-card !important;
    color: $text-color !important;
  }
  .ngx-datatable .datatable-header-cell,
  .ngx-datatable .datatable-body-cell,
  .page-count,
  .ngx-datatable .datatable-footer .datatable-pager a {
    color: $text-color !important;
  }
  .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
    color: $disabled-text-color !important;
  }
  .ngx-datatable.material .datatable-header {
    background: map-get($background, background);
  }
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection)
    .datatable-body-row:hover
    .datatable-row-group {
    background: map-get($background, hover);
  }
}
