/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/
@font-face {
    font-family: "Shamel";
    src: url(../fonts/FFShamelFamily-SansOneBook.ttf);
}
@font-face {
    font-family: "ShamelBoold";
    src: url(../fonts/FFShamelFamily-SansOneBold.ttf);
}



/*default-btn2*/
.default-btnWhite {
    display: inline-block;
    background-color: white;
    color: #222a45;
    transition: .5s;
    border: 2px thin;
    border-color: #222a45;
    padding: 7px 20px 9px;
    border-radius: 10px;

    font: {
        size: 13px;
        weight: 100;
        weight: bold;
    }

    ;

    &:hover {
        background-color: #222a45;
        color: white;
    }
}

/*default-btn2*/
.default-btnBlack {
    display: inline-block;
    background-color: #222a45;
    color: white;
    transition: .5s;
    border: 2px thin;
    border-color: #222a45;
    padding: 7px 20px 9px;
    border-radius: 10px;

    font: {
        size: 13px;
        weight: 100;
        weight: bold;
    }

    ;

    &:hover {
        background-color: white;
        color: #222a45;
    }
}


// // Third pirty style files
// // Sidebar Scroll
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
// // Flag icon
@import '~flag-icon-css/css/flag-icons.min.css';

// //  star-rating styles (Used in Shop app)
@import "~css-star-rating/css/star-rating.min.css";
// // Data Table
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
// // Rich Text Editor
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
// // Drag and Drop
@import '~dragula/dist/dragula.css';
// // Calendar
@import '~angular-calendar/css/angular-calendar.css';

// // User tour
@import '~hopscotch/dist/css/hopscotch.min.css';
// // Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';

// // Highlightjs theme
// // @import '~highlight.js/styles/darcula.css';
// // @import '~highlight.js/styles/github.css';
@import '~highlight.js/styles/github-gist.css';


// Main Variables File
@import "scss/variables";

@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Angular material theme files
@import "scss/themes/init";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";


@import "scss/page-layouts";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";

@import 'custom.css';


